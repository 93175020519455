<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('teaGardenConfig.export_company') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="export_company_name"
              >
                <template v-slot:label>
                  {{ $t('teaGardenConfig.export_company_name') }}
                </template>
                <b-form-input
                    id="export_company_name"
                    type="text"
                    v-model="search.company_name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- search section end -->
      </card>

      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title"> {{ $t('teaGardenConfig.export_company_list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <a href="javascript:" class="btn-add mx-2" @click="pdfExport"><i class="ri-printer-fill text-right"></i> {{$t('globalTrans.export_pdf') }}</a>
          <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <base-table v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :columns="columns" :componentId="3" :search="search" :searchData="searchData" :paginations="pagination" :actions="actions" :imageUrl ="teaGardenServiceBaseUrl" />
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
      <b-modal id="details" size="lg" :title="$t('teaGardenConfig.export_company') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
         <Details :id="detailItemId" :key="detailItemId"/>
      </b-modal>
    </div>
  </template>
  <script>
  import Form from './Form.vue'
  import Details from './Details'
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { exportCompanyList, exportCompanyToggle } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'

  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form, Details
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        editItemId: 0,
        search: {
          company_name: '',
          owner_name_bn: '',
          owner_name_en: '',
          contact_no: '',
          email: '',
          org_id: 0,
          limit: 20
        },
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
          { labels: 'teaGardenConfig.export_company_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: 'auto' } },
          { labels: 'teaGardenConfig.company_owner_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: 'auto' } },
          { labels: 'teaGardenConfig.contact_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: 'auto' } },
          { labels: 'teaGardenConfig.email', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: 'auto' } },
          { labels: 'teaGardenConfig.company_address', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: 'auto' } },
          { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
          { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 8, thStyle: { width: '10%' } }
        ],
        actions: {
          edit: true,
          toogle: true,
          details: true,
          delete: false
        }
      }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'company_name_bn' },
            { key: 'owner_name_bn' },
            { key: 'contact_no' },
            { key: 'email' },
            { key: 'address_bn' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'company_name_en' },
            { key: 'owner_name_en' },
            { key: 'contact_no' },
            { key: 'email' },
            { key: 'address_en' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('teaGardenConfig.export_company') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenConfig.export_company') + ' ' + this.$t('globalTrans.modify')
      },
      factoryTypeList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.filter(item => item.status === 1)
      }
    },
    methods: {
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        } else if (type === 2) {
          this.changeStatus(3, teaGardenServiceBaseUrl, exportCompanyToggle, item)
        } else if (type === 3) {
          this.details(item)
        }
      },
      searchData () {
        this.loadData()
      },
      async pdfExport () {
          const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 5 })
          // if (this.authUser) {
          //     if (this.authUser.office_detail && this.authUser.office_detail.office_type_id) {
          //         params.office_type_id = this.authUser.office_detail.office_type_id
          //     }
          //     if (this.authUser.office_detail && this.authUser.office_detail.office_id) {
          //         params.office_id = this.authUser.office_detail.office_id
          //     }
          // }
          const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
            if (service !== undefined) {
                if (service.office_type_id) {
                    params.office_type_id = service.office_type_id
                }
                if (service.office_id) {
                    params.office_id = service.office_id
                }
            }
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, exportCompanyList, params)
          var blob = new Blob([result], {
              type: 'application/pdf'
          })
          var url = window.URL.createObjectURL(blob)
          window.open(url).print()
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, exportCompanyList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            return Object.assign({}, item, { serial: index })
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
  </script>
